
import { AttachFile } from "@mui/icons-material";
import { Button, CircularProgress, Grid, TextareaAutosize } from "@mui/material";
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete.js";
import CustomizedSnackbars from "src/components/material-snackbars";
import * as QuickResponseService from "src/services/2talk/quick-response.service";
import Api from "src/services/api";
import Api2Talk from "src/services/api2talk";
import { getFileDownloadLink } from "src/services/attachment.service";
import { Overlay } from "src/styles/global";
import { MESSAGE_PROVIDER_WEBCHAT } from "src/utils/contantes/message-provider";
import { v4 as uuidv4 } from 'uuid';
import * as componentActions from '../../../../redux/actions/chats';
import { Styles } from '../../../../styles/material-styles';


const QuickResponseModal = (props) => {

  const {
    quickResponse,
    chats, 
    phonenumberid, 
    customerid, 
    userSession,
    onSetOpenModal,
    onSetCurrentQuickResponseSeledted,
    onSendMessage
  } = props;

  const [message, setMessage] = useState("");

  const [quickResponseList, setQuickResponseList] = useState([]);
  const [quickResponseSelected, setQuickResponseSelected] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState('error');
  const [notificationMessage, setNotificationMessage] = useState('');

  const messageProviderId = useMemo(() => chats?.chat?.messageproviderid ?? null, [chats]);

  const setAlert = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const intl = useIntl();

  const getFile = async (attachment) => {
    setLoading(true);
		let url = await getFileDownloadLink(attachment.attachmentid);

		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		} else{
      setAlert(true, 'error', "Arquivo não encontrado");
    }

    setLoading(false);
  }

  const getQuickResponses = async () => {
    setLoading(true);

    try {
      const response = await QuickResponseService.getAllQuickResponse();

      if (response?.success) {

        if (response?.data?.length > 0) {
          const items = response.data.map(item => ({id: item?.id, name: item?.title}));
          setQuickResponseList(items);
        }
      } else {
        let alertMsg = response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" });
        setAlert(true, 'error', alertMsg);
        Api.kickoff(response);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        setLoading(false);
        let alertMsg = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
        setAlert(true, 'error', alertMsg);
        Api.kickoff(err);
      }
      else {
        setLoading(false);
        let alertMsg = err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
        setAlert(true, 'error', alertMsg);
        Api.kickoff(err);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleSentMessage = (e) => {
    e.preventDefault();

    if (!message || !message.replace(/<[^>]*>/g, '').trim().length > 0) {
      setAlert(true, 'error', "O campo de mensagem não por ser vazio");
      return false;
    }

    sendQuickResponseMessage();
    onSetCurrentQuickResponseSeledted(null);
    onSetOpenModal(false); 
  }

  const sendQuickResponseMessage = async () => {

    if (message.length > 0) {

      let chat = chats.chat;
      var chatid = null;

      if ((phonenumberid && phonenumberid.phonenumberid > 0) || (chat && chat.customerid && chat.customerid > 0)) {

        if ((customerid && customerid > 0) || (customerid.id > 0) || (chat && chat.customerid && chat.customerid > 0)) {
          let guid = uuidv4();
 
          var msg = {
            content: message,
            guid: guid,
            to: customerid,
            userid: userSession.user.id,
            createdat: moment().format('YYYY/MM/DD HH:mm:ss'),
            createtime: moment().format('HH:mm'),
            icon: 'request',
            channelid: { id: phonenumberid.channelid },
            techinicalteamid: { id: phonenumberid.techinicalteamid },
            originrequestid: phonenumberid.originrequestid,
          };

          if (customerid.id > 0) {
            msg.to = customerid.phone;
            msg.customerid = customerid.id;
          }

          if (chat && chat.customerid && chat.customerid > 0) {
            chatid = chat.id;
            msg.channelid = { id: chat.channelid };
            msg.techinicalteamid = { id: chat.techinicalteamid };
            msg.to = chat.customer.phone;
            msg.messageproviderid = chat.messageproviderid;
            msg.messageproviderconfigid = chat.messageproviderconfigid;
            msg.customerid = chat.customerid;
            msg.originrequestid = chat.originrequestid;
            msg.phonenumberid = {
              id: chat.registrationnumberrequestid, 
              messageproviderid: chat.messageproviderid, 
              messageproviderconfigid: chat.messageproviderconfigid, 
              organizationid: chat.organizationid, 
              organizationname: chat.organizationname, 
              requeststatusid: chat.chatstatusid, 
              originrequestid: chat.originrequestid
            };
          }

          if (chat && chat.chatmessages) {
            var m = {
              channelid: msg.channelid.id,
              chatid: chatid,
              content: msg.content,
              createdat: msg.createdat,
              delivered: false,
              read: false,
              request: true,
              senderuserid: msg.userid,
              sent: false,
              guid: guid,
              sendername: userSession.user.name,
              messageorigin : 1
            };
            chat.chatmessages.push(m)
            await props.addCurrentChat(chat)
          }

          onSendMessage(msg, chatid);
        }
      }
    }
  }

  const handleChangeQuickResponse = (value) => {
    setQuickResponseSelected(value);

    if (value && value?.id > 0) {
      setLoading(true);

      Api2Talk.get(`/quickresponses/${value.id}`)
      .then(result => {
        if (result.data.success) {
          setLoading(false);
          onSetCurrentQuickResponseSeledted(result.data.data);

          if (messageProviderId === MESSAGE_PROVIDER_WEBCHAT) {
            setMessage(result.data.data?.content ?? "");
          } else {
            setMessage(result.data.data?.contenttext ?? "");
          } 
        }
        else {   
          setLoading(false);
          let alertMsg = result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false);
          let alertMsg = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
          Api.kickoff(err);
        }
        else {
          setLoading(false);
          let alertMsg = err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
          Api.kickoff(err);
        }
      });
    } else {
      onSetCurrentQuickResponseSeledted(null);
      setMessage("");
    }
  }

  useState(() => {
    setMessage(quickResponse?.content ?? "");
    if (messageProviderId === MESSAGE_PROVIDER_WEBCHAT) {
      setMessage(quickResponse?.content ?? "");
    } else {
      setMessage(quickResponse?.contenttext ?? "");
    } 
  }, [quickResponse, messageProviderId]);

  useState(() => {
    if (!quickResponse && quickResponseList?.length === 0) {
      getQuickResponses();
    }
  }, [quickResponse, quickResponseList]);

  return (

    <div>
      {
        quickResponseList?.length > 0 && (
          <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
            <SimpleAutoComplete 
              label={intl.formatMessage({ id: "select.quickResponse" })} 
              options={quickResponseList} 
              stateName="quickResponse"
              changeSelect={(stateName, value) => handleChangeQuickResponse(value)} 
              selected={quickResponseSelected} 
            />
          </Grid>
        )
      }

      <label style={{width:"100%"}}>Conteúdo
        {
          messageProviderId === MESSAGE_PROVIDER_WEBCHAT
          ? (
            <div style={{ width:"100%", resize: "vertical", overflow: "auto", height: "180px", marginBottom: "24px", border: "1px solid #ccc" }}>
              <ReactQuill
                  name="description"
                  style={{ height: "100%", marginBottom: '82px', width: "100%" }}
                  fullWidth
                  variant="outlined"
                  id="descriprion"
                  value={message}
                  onChange={(value) => setMessage(value)}
              />
            </div>
          )
          : (
            <div style={{ marginBottom: "24px" }}>
              <TextareaAutosize 
                minRows={8} 
                fullWidth 
                style={{ width:"100%" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          )
        }
      </label>

    {quickResponse?.attachments?.length > 0 && (
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>  
          <label>{<FormattedMessage id="attachments" />}</label>
          <ul style={{display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center', padding: '0px'}}>
              {quickResponse?.attachments.map(file => {
                return (
                <li key={file.attachmentid} style={{display: 'flex', alignItems: 'center'}}>
                  <AttachFile /> 
                  <Button 
                    onClick={() => getFile(file)}
                  >
                    {file.name}
                  </Button>
                </li>)
              })}
            </ul>
        </div>
      </Grid>
    )}

    <div style={{display: 'flex', justifyContent: 'end'}}>
      <Button
        type='button'
        fullWidth
        variant='contained'
        color='primary'
        style={{width: '200px'}}
        onClick={handleSentMessage}
      >
        {<FormattedMessage id="Enviar menssagem" />}
      </Button>
    </div>
    
    {loading && (
      <Overlay>
          <CircularProgress color='secondary' />
      </Overlay>
    )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} 
      />
    </div>
  )
}

QuickResponseModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(QuickResponseModal)));